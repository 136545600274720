const components = {
  dialog: {
    iconColor: 'body',
    cardCancel: {
      variant: 'primary',
    },
    contentColor: 'content',
    button: {
      variant: 'secondary',
    },
    skin: 'Skin3',
    backgroundColor: 'body',
    headBoxShadow: false,
    contentBackground: null,
    cancelContentColor: 'black',
    contentFontFamily: 'fontText',
    headerColor: 'main',
  },
  album: {
    contentColor: 'content',
    wrapperBackground: {
      odd: 'body',
      even: 'body',
    },
    textFieldVariant: 'default',
    buttonVariant: 'primary',
    accentColor: 'darkBackground',
  },
  backToTop: {
    fontWeight: 'normal',
    fontSize: 10,
    fontSizeMobile: 10,
    fontFamily: 'fontText',
    skin: 'Skin2',
    textTransform: 'uppercase',
    contentColor: 'content',
  },
  textField: {
    rounded: false,
    phoneCountrySelect: {
      textColor: 'content',
      arrowColor: 'main',
      background: 'body',
      highlightedBackground: 'lightBackground',
    },
    skin: 'Skin6',
    helperMessageColor: null,
    borderRadius: 4,
    focusBorderColor: 'content',
    label: {
      letterSpacing: '1px',
      textTransform: '',
      selected: {
        color: 'content',
        fontFamily: null,
        textTransform: 'uppercase',
        fontSize: 10,
      },
    },
    contentColor: 'content',
  },
  photo: {
    fullWidth: false,
    floatingBackground: [],
    skin: 'Skin1',
    wrapperBackground: {
      odd: 'transparentBackground',
      even: 'transparentBackground',
    },
  },
  externalStore: {
    infoBorderRadius: '0px',
    containerBackground: 'body',
    contentBackground: 'lightBackground',
    siteLink: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
    skin: 'Skin1',
    photoWrapperBackground: null,
    wrapperBackground: 'body',
  },
  recentPhotos: {
    divider: true,
  },
  subtitle: {
    lineHeightMobile: '20',
    lineHeight: '28',
    letterSpacingMobile: '2px',
    textTransform: 'uppercase',
    fontFamily: 'fontText',
    fontSize: '14',
    fontStyle: 'normal',
    textColor: 'content',
    letterSpacing: '2px',
    fontSizeMobile: '10',
    lineHeightWithoutPixel: false,
  },
  cartButton: {
    notificationTextColor: 'white',
    notificationBackgroundColor: 'alert',
    backgroundColor: 'main',
    iconColor: 'white',
  },
  selectField: {
    borderRadius: 4,
    rounded: false,
    arrowColor: 'main',
  },
  welcome: {
    default: {
      showTitle: true,
    },
    attachedToHero: {
      showTitle: true,
    },
  },
  brand: {
    color: 'content',
    opacityLevel: 0.40000000000000002,
    opacity: true,
    opacityLevelHover: 0.69999999999999996,
    colorHover: 'content',
  },
  cardItem: {
    skin: 'Skin1',
    width: '164px',
    padding: '12px 4px',
    buttons: {
      fontFamily: 'fontMenu',
      select: {
        backgroundColor: 'white',
        border: '1px solid var(--white)',
        color: 'content',
      },
      selected: {
        backgroundColor: 'transparentBackground',
        border: '1px solid var(--main)',
        color: 'white',
      },
      preview: {
        backgroundColor: 'main',
        border: '1px solid var(--main)',
        color: 'white',
      },
    },
    borderRadius: '0',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  recaptcha: {
    fontSize: 10,
    link: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
    iconColor: 'body',
    borderRadius: null,
    iconOpacity: 1,
  },
  map: {
    fullWidth: false,
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
    borderWidth: {
      desktop: 0,
      mobile: 0,
    },
  },
  radio: {
    selected: {
      markerColor: 'content',
      borderColor: 'content',
    },
    empty: {
      borderColor: 'content',
    },
  },
  forecast: {
    fontSize: 14,
    dateFontWeight: false,
  },
  rsvp: {
    wrapperBackground: 'lightBackground',
  },
  decorativeDivider: {
    color: 'main',
    skin: 'Skin204',
    display: true,
  },
  tips: {
    skin: 'Skin1',
  },
  hero: {
    skin: 'Skin207',
  },
  loading: {
    loaderColor: 'main',
    wrapperBackground: 'body',
  },
  text: {
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
  },
  button: {
    borderRadius: 0,
    tertiaryProps: {
      darken: null,
      disabled: {
        opacity: 0.5,
      },
      hover: {
        backgroundOpacity: false,
        background: 'main',
        textColor: 'white',
        border: {
          opacity: 1,
          value: '1px solid',
          color: 'main',
        },
        backgroundDarken: false,
      },
      textColor: 'content',
      background: 'transparentBackground',
      borderColor: 'main',
      border: {
        opacity: 1,
        value: '1px solid',
        color: 'main',
      },
      fontWeight: false,
    },
    fontSize: 14,
    contrastOffset: 1,
    rounded: false,
    skin: 'Skin6',
    fontFamily: false,
    border: {
      opacity: 1,
      value: '1px solid',
      color: 'main',
    },
    disabled: {
      opacity: 0.5,
    },
    fontWeight: false,
    hover: {
      border: {
        opacity: 1,
        value: '1px solid',
        color: 'main',
      },
      backgroundDarken: 0.10000000000000001,
      backgroundOpacity: false,
      textColor: 'white',
      background: 'main',
    },
    textColor: 'white',
    borderColor: 'main',
    fontSizeMobile: 14,
    secondaryProps: {
      background: 'transparentBackground',
      disabled: {
        opacity: 0.5,
      },
      fontWeight: false,
      borderColor: 'main',
      hover: {
        border: {
          opacity: 1,
          value: '1px solid',
          color: 'main',
        },
        backgroundDarken: false,
        backgroundOpacity: false,
        textColor: 'white',
        background: 'main',
      },
      border: {
        opacity: 1,
        value: '1px solid',
        color: 'main',
      },
      textColor: 'content',
      darken: null,
    },
    darkenOnHover: false,
    boxShadow: null,
    textTransform: 'none',
    background: 'main',
    darken: null,
  },
  navigation: {
    menuInactiveIconColor: 'menu',
    textTransform: 'none',
    topDistance: 56,
    fontSize: 12,
    attachedToHero: {
      hamburguerColor: 'white',
    },
    wrapperBackground: 'body',
    menuIconColor: 'menu',
    lateralDistance: 32,
    contentColor: 'menu',
    skin: 'Skin1',
  },
  shareWhatsapp: {
    buttonBorderColor: 'main',
    buttonBorderRadius: '0',
    iconColor: 'main',
    fontFamily: null,
    buttonFontSize: '14px',
    fontWeight: false,
    boxShadow: false,
    buttonTextColor: 'content',
  },
  cartConfirmation: {
    strong: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  messagesHome: {
    skin: 'Skin1',
    wrapperBackground: 'body',
  },
  navigationDropdown: {
    titleColorMobile: 'menu',
    titleFontWeightMobile: null,
    borderTop: 'none',
    attachedToHero: {
      titleColor: 'white',
      svgColor: 'white',
    },
    borderColor: 'content',
    borderTopAttachedToHero: '1px solid',
    activeLinkFontWeight: 'bold',
    arrowColor: 'main',
    skin: 'Skin1',
    activeLinkFontFamily: 'fontText',
    borderOpacity: 0.20000000000000001,
  },
  tracker: {
    valueTypography: false,
    labelTextTransform: 'capitalize',
    valueFontSize: 28,
    topBorderRadius: '4px 4px 0 0',
    valueFontSizeMobile: 20,
    bottomBorderRadius: '0 0 4px 4px',
    textTypography: false,
    textColor: false,
    skin: 'Skin3',
    valueFontFamily: null,
  },
  messagesForm: {
    wrapperBackground: 'lightBackground',
  },
  internalHero: {
    skin: 'Skin1',
  },
  cardDialogCancel: {
    strong: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
    background: false,
  },
  footer: {
    borderTopOpacity: '0.2',
    borderTopColor: 'content',
  },
  title: {
    lineHeightMobile: '32.11px',
    lineHeight: '46.7px',
    textColor: 'content',
    textTransform: 'none',
  },
  header: {
    attachedToHero: {
      coupleNameStyle: 'normal',
      coupleNameOption: 'firstLetter',
      coupleNameWeight: 400,
      borderOpacity: 0,
      coupleNameColor: 'white',
      coupleNameSize: null,
      borderBottomColor: 'menu',
      showFlag: false,
      menuColor: 'menu',
      wrapperBackground: 'transparentBackground',
    },
    dividerColor: false,
    fontFamily: 'fontTitle',
    skin: 'Skin1',
    whenShowNavigation: {
      coupleNameStyle: null,
      coupleNameSize: null,
      coupleNameColor: 'menu',
      coupleNameWeight: null,
    },
    default: {
      coupleNameStyle: 'normal',
      coupleNameOption: 'firstLetter',
      coupleNameWeight: 400,
      borderOpacity: 0.20000000000000001,
      coupleNameColor: 'menu',
      coupleNameSize: null,
      borderBottomColor: 'content',
      showFlag: true,
      menuColor: 'menu',
      wrapperBackground: 'body',
    },
  },
  place: {
    skin: 'Skin1',
    imageFormat: 'square',
    mobileContainerBackground: 'lightBackground',
    containerBackground: 'lightBackground',
    containerBorderRadius: '0px',
    wrapperBackground: 'body',
    siteLink: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  navigationCheckout: {
    iconOpacity: '1',
    iconColor: 'main',
    color: false,
    textTransform: 'uppercase',
  },
  video: {
    skin: 'Skin1',
    borderWidth: {
      desktop: 0,
      mobile: 0,
    },
    fullWidth: false,
    floatingBackground: [],
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
  },
  selectFieldCustom: {
    textTransform: null,
    border: '1px solid var(--formBorder)',
    borderRadius: null,
    borderRadiusWhenOpened: null,
    fontSizeMobile: null,
    textColor: null,
    fontFamily: null,
    arrowColor: 'main',
  },
  messages: {
    replyAccentOpacity: 1,
    messageAccentColor: 'main',
    replyBorder: null,
    messageAccentOpacity: 1,
    replyBackground: null,
    dividerOpacity: 0.20000000000000001,
    fontWeight: 'bold',
    skin: 'Skin1',
    replyBorderRadius: '0px',
    replyAccentColor: 'content',
  },
  survey: {
    barColor: 'main',
    skin: 'Skin1',
    floatingBackground: [],
    borderRadiusLastOption: '0px',
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
    decorativeDivider: false,
    borderRadiusFirstOption: '0px',
    containerBackground: {
      even: 'body',
      odd: 'body',
    },
    barOpacity: '0.2',
  },
  store: {
    categories: {
      textTransform: false,
      borderRadius: false,
      borderColor: false,
      titleSize: null,
      titleColor: 'content',
    },
    filters: {
      wrapperBackgroundMobileOpacity: 1,
      wrapperBackgroundOpacity: 1,
      buttons: {
        borderColor: 'main',
      },
      changeColorOnSelected: true,
      wrapperBackgroundMobile: 'lightBackground',
      borderRadius: '0px',
      wrapperBackground: 'lightBackground',
      borderColor: 'transparentBackground',
      fullWidth: false,
    },
  },
  rsvpHome: {
    divider: true,
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
  },
  cardDialog: {
    textColor: 'content',
    background: 'white',
  },
  bestman: {
    contentBackground: 'lightBackground',
    titleColor: 'content',
    wrapperBackground: 'body',
    contentAlign: 'center',
    contentBorderRadius: '0px',
    imageFormat: 'square',
    containerBackground: 'body',
    titleSize: '24px',
  },
  countdown: {
    wrapperBackground: 'body',
    titleLetterSpacingMobile: '2px',
    titleColor: 'content',
    countdownColor: 'main',
    display: true,
    titleFontSizeMobile: 12,
    titleFontStyle: 'normal',
    textTransform: 'uppercase',
    titleTipography: 'content',
    floatingBackground: [],
    titleLetterSpacing: '2px',
    titleFontSize: 14,
    opacity: '1',
    contentColor: 'body',
  },
  product: {
    value: {
      fontFamily: false,
    },
    skin: 'Skin1',
    borderRadius: 4,
    wrapperBackground: 'lightBackground',
    borderAddedCart: 'transparentBackground',
    border: 'transparentBackground',
    rounded: false,
    button: {
      textTransform: '',
      addedBorder: 'main',
      addedBackground: 'transparentBackground',
      disabledBackground: 'transparentBackground',
    },
  },
  socialIcon: {
    skin: 'Skin16',
    backgroundOpacity: 1,
    backgroundColor: 'main',
    boxShadow: null,
    borderRadius: null,
    backgroundOpacityHover: 1,
    rounded: false,
    backgroundColorHover: 'body',
  },
  cart: {
    stepsSkin: 'Skin1',
    steps: {
      buttonTextTransform: 'none',
      activeButtonBackground: 'main',
      disabledButtonBorder: {
        value: '1px solid',
        color: 'main',
      },
      disabledButtonBorderRadius: null,
      disabledButtonOpacity: 1,
      activeButtonBorderRadius: null,
      activeButtonBorder: {
        value: '1px solid',
        color: 'main',
      },
      activeButtonColor: 'white',
    },
    slickButtonColor: 'content',
    stepsPosition: '',
    resumeTextColor: 'content',
    wrapperBackground: 'lightBackground',
    removeTextColor: 'content',
    quantityBox: {
      borderRadius: false,
    },
    resumeDividerBorderColor: null,
  },
  supplier: {
    siteLink: {
      fontWeight: 'bold',
      fontFamily: 'fontText',
    },
    contentBackground: 'lightBackground',
    infoBorderRadius: '0px',
    containerBackground: 'body',
    skin: 'Skin1',
    wrapperBackground: 'body',
  },
  charDivider: '+',
  flag: {
    backgroundColor: 'main',
    boxShadow: false,
    textTransform: 'none',
    fontFamily: 'fontText',
    fontWeight: '400',
    linesColor: 'white',
    color: 'white',
  },
  payment: {
    borderRadius: false,
  },
}

export default components
