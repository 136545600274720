const typography = {
  titleLG: {
    mobile: {
      fontSize: 32,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
    desktop: {
      fontSize: 40,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
  },
  contentMobile: {
    mobile: {
      fontSize: 12,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 0,
    },
    desktop: {
      fontSize: 14,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 0,
    },
  },
  menu: {
    mobile: {
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontStretch: 'normal',
      letterSpacing: '2px',
    },
    desktop: {
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontStretch: 'normal',
      letterSpacing: '2px',
    },
  },
  titleXL: {
    mobile: {
      fontSize: 22,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 0,
    },
    desktop: {
      fontSize: 80,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 0,
    },
  },
  titleXS: {
    mobile: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontStretch: 'normal',
      fontWeight: 400,
      letterSpacing: '2px',
    },
    desktop: {
      fontSize: 14,
      textTransform: 'uppercase',
      fontStretch: 'normal',
      fontWeight: 400,
      letterSpacing: '2px',
    },
  },
  warning: {
    mobile: {
      fontSize: 10,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
    desktop: {
      fontSize: 10,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
  },
  titleSM: {
    mobile: {
      fontWeight: 600,
      fontStretch: 'normal',
      fontSize: 20,
      letterSpacing: 'normal',
    },
    desktop: {
      fontSize: 28,
      fontWeight: 600,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
  },
  content: {
    mobile: {
      fontSize: 12,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
    desktop: {
      fontSize: 14,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
  },
  titleMD: {
    mobile: {
      fontSize: 24,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
    desktop: {
      fontSize: 32,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
  },
}

export default typography
