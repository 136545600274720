import React from 'react'
import PropTypes from 'prop-types'
import plans from '../../../enums/plans'

import Icons from './assets'
import * as S from './styles'

const Brand = ({ link, planId, isPlanApple }) => {
  const Icon = isPlanApple && planId === plans.BASIC ? Icons[1] : Icons[planId]

  return (
    <S.Wrapper
      href={link}
      target="_blank"
      rel="nofollow"
      aria-label="Site iCasei"
    >
      <Icon width="100%" height="100%" />
    </S.Wrapper>
  )
}

Brand.defaultProps = {
  link: 'https://www.icasei.com.br/site-de-casamento',
  planId: plans.TRIAL,
  isPlanApple: false,
}

Brand.propTypes = {
  link: PropTypes.string,
  planId: PropTypes.oneOf([
    plans.TRIAL,
    plans.BASIC,
    plans.CLASSIC,
    plans.BLACK,
    plans.TMD,
  ]),
  isPlanApple: PropTypes.bool,
}

export default Brand
