import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import Brand from '../../atoms/Brand'
import BrandPartner from '../../atoms/BrandPartner'
import Social from '../../atoms/Social'
import * as S from './styles'
import api from '../../../services/api'

import plans from '../../../enums/plans'

const Footer = () => {
  const { profile } = useSelector((state) => state.user)
  const theme = useTheme()
  const [partnerLogo, setPartnerLogo] = useState(null)

  useEffect(() => {
    if (profile?.partner_id) {
      api
        .get(`api/v1/partners/${profile.partner_id}`, {
          params: { couple_id: profile.couple_id },
        })
        .then((response) => {
          setPartnerLogo(response.data.data.partner.brand?.magic_url)
        })
        .catch((error) => {
          console.error('Erro ao fazer a requisição:', error)
        })
    }
  }, [profile?.partner_id, profile.couple_id])

  return (
    <S.Footer id="footer">
      <S.Container>
        <S.SocialList isShowing={!!profile.exibition_configs.social_icons}>
          {profile.exibition_configs.social_icons && (
            <>
              <S.SocialItem>
                <Social name="facebook" />
              </S.SocialItem>

              <S.SocialItem>
                <Social name="twitter" />
              </S.SocialItem>

              <S.SocialItem>
                <Social name="pinterest" />
              </S.SocialItem>
            </>
          )}
        </S.SocialList>

        {profile.exibition_configs.visitors && (
          <S.Text>
            <S.VisitorsAmount>{profile.visitors}</S.VisitorsAmount> visitantes
            já conferiram nosso{' '}
            <a
              href="https://www.icasei.com.br/site-de-casamento"
              rel="nofollow noreferrer"
              target="_blank"
            >
              site de casamento
            </a>
            .
          </S.Text>
        )}

        {partnerLogo ? (
          <BrandPartner partnerLogo={partnerLogo} />
        ) : (
          <Brand
            planId={theme.configs.isTailorMade ? plans.TMD : profile.plan_id}
            isPlanApple={profile.apple_plan}
          />
        )}
      </S.Container>
    </S.Footer>
  )
}

export default Footer
