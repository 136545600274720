import React from 'react'
import PropTypes from 'prop-types'

import Icons from '../Brand/assets'
import * as S from './styles'

const BrandPartner = ({ link, partnerLogo }) => {
  const IconIcasei = Icons[1]

  return (
    <S.PartnerWrapper>
      <S.Wrapper
        href={link}
        target="_blank"
        rel="nofollow"
        aria-label="Site iCasei"
      >
        <IconIcasei width="100%" height="100%" />
      </S.Wrapper>
      <div />
      <S.PartnerLogo>
        <img src={partnerLogo}></img>
      </S.PartnerLogo>
    </S.PartnerWrapper>
  )
}

BrandPartner.defaultProps = {
  link: 'https://www.icasei.com.br/site-de-casamento',
}

BrandPartner.propTypes = {
  link: PropTypes.string,
  partnerLogo: PropTypes.string,
}

export default BrandPartner
