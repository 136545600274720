import React from 'react'

import * as S from './styles'

import Spacer from '../../atoms/Spacer'

const ShareWhatsapp = () => {
  const share = () => {
    window.location = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      window.location.href
    )}`
  }

  return (
    <S.Wrapper>
      <Spacer size="small" />
      <S.Button onClick={share}>
        Compartilhar no WhatsApp
        <S.Icon />
      </S.Button>
      <Spacer size="small" />
    </S.Wrapper>
  )
}

export default ShareWhatsapp
