import styled, { css } from 'styled-components'

export const Wrapper = styled.a.attrs({
  className: 'brand',
})`
  ${({ theme }) => css`
    display: block;
    max-width: 80px;
    width: 100%;
    max-height: 45px;
    height: 100%;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    color: ${theme.colors[theme.components.brand.color]};
    opacity: ${theme.components.brand.opacity
      ? theme.components.brand.opacityLevel
      : 1};

    &:hover,
    &:focus {
      color: ${theme.colors[theme.components.brand.colorHover]};
      opacity: ${theme.components.brand.opacity
        ? theme.components.brand.opacityLevelHover
        : 1};
    }
  `};
`

export const PartnerWrapper = styled.div.attrs({
  className: 'partner_brand',
})`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 220px;
  width: 100%;
  gap: 24px;

  @media (max-width: 768px) {
    height: 45px;
  }

  a:nth-child(1) {
    max-width: 64px;
  }

  div:nth-child(2) {
    position: relative;
    background: rgba(49, 45, 36, 0.08);
    width: 1px;
    height: 32px;

    @media (max-width: 768px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  svg {
    margin-top: -14px;

    @media (max-width: 768px) {
      margin-top: -8px;
    }
  }
`
export const PartnerLogo = styled.div.attrs({
  className: 'partner_logo',
})`
  max-width: 102px;
  width: 100%;
  max-height: 45px;
  height: 100%;

  img {
    filter: grayscale(1) opacity(0.75);
    max-width: 102px;
    max-height: 20px;
    object-fit: contain;

    @media (max-width: 768px) {
      position: absolute;
      bottom: 50%;
      left: 82%;
      transform: translate(-40%, 50%);
    }
  }
`
